import { combineReducers, configureStore } from "@reduxjs/toolkit";
// import RTK Query
import { alertsApi } from "./Data/services/alertsApi";
import { blogsApi } from "./Data/services/blogsApi";
import { categoryApi } from "./Data/services/categoryApi";
import { dashboardApi } from "./Data/services/dashboardApi";
import { orderApi } from "./Data/services/orderApi";
import { productApi } from "./Data/services/productApi";
import { promosApi } from "./Data/services/promosApi";
import { settingsApi } from "./Data/services/settingsApi";
import { subCategoryApi } from "./Data/services/subCategoryApi";
import { userApi } from "./Data/services/userApi";
import { userAuthApi } from "./Data/services/userAuthApi";
// import feature
import { persistStore } from "redux-persist";
import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";
import productSlice from "./Data/features//productSlice";
import alertsSlice from "./Data/features/alertsSlice";
import authReducer from "./Data/features/authSlice";
import blogsSlice from "./Data/features/blogsSlice";
import categoryReducer from "./Data/features/categorySlice";
import dashboardSlice from "./Data/features/dashboardSlice";
import orderSlice from "./Data/features/orderSlice";
import promosSlice from "./Data/features/promosSlice";
import settingsSlice from "./Data/features/settingsSlice";
import subCategoriesReducer from "./Data/features/subCategorySlice";
import userReducer from "./Data/features/userSlice";
import sessionMiddleware from "./Data/middleware/sessionMiddleware";
import { paymentApi } from "./Data/services/paymentApi";

const rootReducer = combineReducers({
  // Add the generated reducer as a specific top-level slice
  // RTK Query API
  [userAuthApi.reducerPath]: userAuthApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [categoryApi.reducerPath]: categoryApi.reducer,
  [subCategoryApi.reducerPath]: subCategoryApi.reducer,
  [productApi.reducerPath]: productApi.reducer,
  [orderApi.reducerPath]: orderApi.reducer,
  [promosApi.reducerPath]: promosApi.reducer,
  [alertsApi.reducerPath]: alertsApi.reducer,
  [blogsApi.reducerPath]: blogsApi.reducer,
  [settingsApi.reducerPath]: settingsApi.reducer,
  [dashboardApi.reducerPath]: dashboardApi.reducer,
  [paymentApi.reducerPath]: paymentApi.reducer,
  // Local storage Redux
  auth: authReducer,
  user: userReducer,
  category: categoryReducer,
  subCategories: subCategoriesReducer,
  products: productSlice,
  orders: orderSlice,
  promos: promosSlice,
  alerts: alertsSlice,
  blogs: blogsSlice,
  pages: settingsSlice,
  dashboard: dashboardSlice,
});

const rootMiddleware = (getDefaultMiddleware) =>
  getDefaultMiddleware().concat([
    userAuthApi.middleware,
    userApi.middleware,
    categoryApi.middleware,
    subCategoryApi.middleware,
    productApi.middleware,
    orderApi.middleware,
    promosApi.middleware,
    alertsApi.middleware,
    blogsApi.middleware,
    settingsApi.middleware,
    dashboardApi.middleware,
    paymentApi.middleware,
    sessionMiddleware, // for session check each api response
  ]);

export const persistConfig = {
  key: "root",
  storage,
  blacklist: [], // Reducer keys to omit from persisting
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  // load from local storage
  // preloadedState: loadFromLocalStorage(), //todo:

  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  //todo:
  middleware: rootMiddleware,
});

export const persistor = persistStore(store);

//todo:
// function saveToLocalStorage(state) {
//     try {
//         const serialState = JSON.stringify(state)
//         localStorage.setItem("reduxStore", serialState)
//     } catch (e) {
//         console.warn(e);
//     }
// }

//todo:
// function loadFromLocalStorage() {
//     try {
//         const serialisedState = localStorage.getItem("reduxStore");
//         if (serialisedState === null) return undefined;
//         return JSON.parse(serialisedState);
//     } catch (e) {
//         console.warn(e);
//         return undefined;
//     }
// }

//todo:
// store.subscribe(() => saveToLocalStorage(store.getState()));

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
// setupListeners(store.dispatch) //todo:
