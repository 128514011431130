// const BASE_URL = "http://localhost:3001";
// const BASE_URL = "http://buyatbundles.trangotechdevs.com:3015/api/v1/";
// const BASE_URL = "http://staging.buyatbundles.trangotechdevs.com:3037/api/v1/"
// const BASE_URL = "http://buyatbundles.trangotechdevs.com:3015/api/v1/";
const BASE_URL = "https://api.buyatbundles.com/api/v1/";
const SOCKET_BASE_URL = "https://api.buyatbundles.com";
// const BASE_URL = "https://staging.buyatbundles.trangotechdevs.com/api/v1/"


const API_URL = BASE_URL + "/api/admin/";

const INITIAL_TOKEN = "e455e259-5ee7-449d-942a-a851af81813b";

export { API_URL, BASE_URL, INITIAL_TOKEN };
